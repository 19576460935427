import logo from './logo.svg';
import './App.css';
import bnb_logo from './images/binance_logo.png';
import ftx_logo from './images/ftx_logo.png';

import { Table, Tag, Space, Typography } from 'antd';
import { Radio, Input } from 'antd';
import { Checkbox, Row, Col } from 'antd';
import { InputNumber, Button } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
// import ReactGA from 'react-ga';

const { Text } = Typography;
// const TRACKING_ID = "G-L51JFZ8VXC"; // bearsignal

// ReactGA.initialize(TRACKING_ID);

const color_red = "#ff4d4f"
const color_green = "#389e0d"
const color_white = "#fafafa"
const color_yellow = "#ffec3d"

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

function App() {
  const columns = [
    {
      title: 'symbol',
      dataIndex: 'symbol',
      key: 'symbol',
    },
    {
      title: "1m",
      dataIndex: "1m",
      key: "1m",
      render: (text, record) => {
        return <p style={{ display: 'inline', color: text >= overRSI ? color_green: text <= underRSI ? color_red: color_white}}>{text}</p>
      }
    },
    {
      title: "3m",
      dataIndex: "3m",
      key: "3m",
      render: (text, record) => {
        return <p style={{ display: 'inline', color: text >= overRSI ? color_green: text <= underRSI ? color_red: color_white}}>{text}</p>
      }
    },
    {
      title: "5m",
      dataIndex: "5m",
      key: "5m",
      render: (text, record) => {
        return <p style={{ display: 'inline', color: text >= overRSI ? color_green: text <= underRSI ? color_red: color_white}}>{text}</p>
      }
    },
    {
      title: "15m",
      dataIndex: "15m",
      key: "15m",
      render: (text, record) => {
        return <p style={{ display: 'inline', color: text >= overRSI ? color_green: text <= underRSI ? color_red: color_white}}>{text}</p>
      }
    },
    {
      title: "30m",
      dataIndex: "30m",
      key: "30m",
      render: (text, record) => {
        return <p style={{ display: 'inline', color: text >= overRSI ? color_green: text <= underRSI ? color_red: color_white}}>{text}</p>
      }
    },
    {
      title: '%15m',
      dataIndex: 'p_15m',
      key: 'p_15m',
      sorter: (a, b) => a['p_15m'] - b['p_15m'],
      defaultSortOrder: 'descend',
      render: (text, record) => {
        return <p style={{ display: 'inline', color: text >= 20 ? color_yellow: color_white}}>{text}</p>
      }
    },
    {
      title: '%spot/fut',
      dataIndex: 'spot_per_future',
      key: 'spot_per_future',
      sorter: (a, b) => a['spot_per_future'] - b['spot_per_future'],
      render: (text, record) => {
        return <p style={{ display: 'inline', color: color_white}}>{text}</p>
      }
    },
  ];
  const listTFOptions = [
    { label: "1m", value: "1m" },
    { label: "3m", value: "3m" },
    { label: "5m", value: "5m" },
    { label: "15m", value: "15m" },
    { label: "30m", value: "30m" },
    { label: "1h", value: "1h" },
    { label: "2h", value: "2h" },
    { label: "4h", value: "4h" },
    { label: "6h", value: "6h" },
    { label: "8h", value: "8h" },
    { label: "12h", value: "12h" },
    { label: "1d", value: "1d" },
  ];
  const listDetail = [
    { label: "price", value: "price" },
    { label: "spot_price", value: "spot_price" },
    { label: "spot_per_future", value: "spot_per_future" },
    { label: "marketcap", value: "marketcap" },
    { label: "funding", value: "funding" },
  ];
  const listCheckedTF = ["1m", "3m", "5m", "15m", "30m"]

  const [tableColumns, setTableColumns] = useState(columns);
  const [tickTF, setTickTF] = useState(listCheckedTF);
  const [tickPC, setTickPC] = useState(["15m"]);
  const  [detailData, setDetailData] = useState(["spot_per_future"])

  const [dataSource, setDataSource] = useState([]);
  const [overRSI, setOverRSI] = useState(70);
  const [underRSI, setUnderRSI] = useState(30);


  const [value_ratio, setValue] = useState(1);
  const [exchanger, setExchanger] = useState("ex_binance");
  const [logo_ex, setLogoEx] = useState(bnb_logo);

  const changeRatio = e => {
    setValue(e.target.value)
  }

  const changeOverRsi = e => {
    setOverRSI(e)
  }
  const changeUnderRsi = e => {
    setUnderRSI(e)
  }

  const changeExchanger = e => {
    setExchanger(e.target.value);
    if (e.target.value === 'ex_binance') {
      setLogoEx(bnb_logo)
    }
    if (e.target.value === 'ex_ftx') {
      setLogoEx(ftx_logo)
    }
  }

  const changeCheckTF = e => {
    setTickTF(e)
    const newListColumns = [{
      title: 'symbol',
      dataIndex: 'symbol',
      key: 'symbol',
    }]
    for (let item of e) {
      const row = {
        title: item,
        dataIndex: item,
        key: item,
        render: (text, record) => {
          return <p style={{ display: 'inline', color: text >= overRSI ? color_green : text <= underRSI ? color_red: color_white }}>{text}</p>
        }
      }
      newListColumns.push(row);
    }
    for (let item of tickPC) {
      const row = {
        title: "%" + item,
        dataIndex: "p_" + item,
        key: "p_" + item,
        sorter: (a, b) => a['p_' + item] - b['p_' + item],
        render: (text, record) => {
          return <p style={{ display: 'inline', color: text >= 20 ? color_yellow: color_white}}>{text}</p>
        }
      }
      newListColumns.push(row);
    }
    for (let item of detailData) {
      const row = {
        title: item == "spot_per_future" ? "%spot/fut" :item,
        dataIndex: item,
        key: item,
        render: (text, record) => {
          return <p style={{ display: 'inline', color: color_white }}>{text}</p>
        }
      }
      newListColumns.push(row);
    }
    setTableColumns(newListColumns)
  }

  const changeCheckPercent = e => {
    setTickPC(e)
    const newListColumns = [{
      title: 'symbol',
      dataIndex: 'symbol',
      key: 'symbol',
    }]
    for (let item of tickTF) {
      const row = {
        title: item,
        dataIndex: item,
        key: item,
        render: (text, record) => {
          return <p style={{ display: 'inline', color: text >= overRSI ? color_green : text <= underRSI ? color_red :color_white }}>{text}</p>
        }
      }
      newListColumns.push(row);
    }
    for (let item of e) {
      const row = {
        title: "%" + item,
        dataIndex: "p_" + item,
        key: "p_" + item,
        sorter: (a, b) => a['p_' + item] - b['p_' + item],
        render: (text, record) => {
          return <p style={{ display: 'inline', color: text >= 20 ? color_yellow: color_white}}>{text}</p>
        }
      }
      newListColumns.push(row);
    }
    for (let item of detailData) {
      const row = {
        title: item == "spot_per_future" ? "%spot/fut" :item,
        dataIndex: item,
        key: item,
        render: (text, record) => {
          return <p style={{ display: 'inline', color: color_white }}>{text}</p>
        }
      }
      newListColumns.push(row);
    }
    setTableColumns(newListColumns)
  }
  const changeDetailData = e => {
    setDetailData(e)
    const newListColumns = [{
      title: 'symbol',
      dataIndex: 'symbol',
      key: 'symbol',
    }]
    for (let item of tickTF) {
      const row = {
        title: item,
        dataIndex: item,
        key: item,
        render: (text, record) => {
          return <p style={{ display: 'inline', color: text >= overRSI ? color_green : text <= underRSI ? color_red :color_white }}>{text}</p>
        }
      }
      newListColumns.push(row);
    }
    for (let item of tickPC) {
      const row = {
        title: "%" + item,
        dataIndex: "p_" + item,
        key: "p_" + item,
        sorter: (a, b) => a['p_' + item] - b['p_' + item],
        render: (text, record) => {
          return <p style={{ display: 'inline', color: text >= 20 ? color_yellow: color_white}}>{text}</p>
        }
      }
      newListColumns.push(row);
    }
    for (let item of e) {
      const row = {
        title: item == "spot_per_future" ? "%spot/fut" :item,
        dataIndex: item,
        key: item,
        sorter: item == "funding" ? (a,b)  => a['abs_funding']  - b['abs_funding']: null,
        render: (text, record) => {
          return <p style={{ display: 'inline', color: color_white }}>{text}</p>
        }
      }
      newListColumns.push(row);
    }
    setTableColumns(newListColumns)    
  }


  const updateRSI = e => {
    const newListColumns = [{
      title: 'symbol',
      dataIndex: 'symbol',
      key: 'symbol',
    }]
    for (let item of tickTF) {
      const row = {
        title: item,
        dataIndex: item,
        key: item,
        render: (text, record) => {
          return <p style={{ display: 'inline', color: text >= overRSI ? color_green : text <= underRSI ? color_red :color_white }}>{text}</p>
        }
      }
      newListColumns.push(row);
    }
    for (let item of tickPC) {
      const row = {
        title: "%" + item,
        dataIndex: "p_" + item,
        key: "p_" + item,
        sorter: (a, b) => a['p_' + item] - b['p_' + item],
        render: (text, record) => {
          return <p style={{ display: 'inline', color: text >= 20 ? color_yellow: color_white}}>{text}</p>
        }
      }
      newListColumns.push(row);
    }
    setTableColumns(newListColumns)
  }
  // call render table 
  useInterval(async () => {
    
    let exchanger_text = 'binance'
    if (exchanger === 'ex_ftx') {
      exchanger_text = 'ftx'
    }
    // console.log('ex',exchanger)
    // console.log('txt',exchanger_text)
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ex: exchanger_text })
    };
    try{
      const v = await fetch('http://146.190.94.202:5000', requestOptions)
      const data = await v.json();
      setDataSource(data.main_data)
      // console.log("length origin:", dataSource.length)
      filter_table()
      // console.log("length filtered:", dataSource.length)
    } catch (e) {
      console.log(e);
    }
  }, 3000); // call after 6s

  const filter_table = () => {
    if (value_ratio == 1) {
      return 
    } else if (value_ratio == 2) {
      const filterData = []
      for (let _row of dataSource){
        for (let _tf of tickTF){
          if (_row[_tf] <= underRSI | _row[_tf] >= overRSI){
            filterData.push(_row);
            break 
          }
        }
      }
      setDataSource(filterData)
    } else if (value_ratio == 3) {
      const filterData = []
      for (let _row of dataSource){
        let count_up = 0 
        let count_down = 0
        for (let _tf of tickTF){
          if (_row[_tf] <= underRSI){
            count_down += 1
          }
          if ( _row[_tf] >= overRSI){
            count_up += 1
          }
        }
        if (count_up >=3 | count_down >= 3){
          filterData.push(_row);
        }
      }
      setDataSource(filterData)
    }
  }
  const [showWeb, setShowWeb] = useState(false)
  const onClickPass = e => {
    if (e.target.value === "cubu999") {setShowWeb(false)}
  }
  return (
    
    <div className="BearSignal">
      {/* style={{visibility: !showWeb ? 'hidden':'visible' }} */}
      <p style={{ display: 'inline', visibility: !showWeb ? 'hidden':'visible', display: !showWeb? 'none':'block'}} > 
      <Input.Password placeholder="input password"  onChange={onClickPass}/> 
      </p>
    <div style={{visibility: showWeb ? 'hidden':'visible'}}>
      <Radio.Group onChange={changeExchanger} defaultValue="ex_binance">
        <Radio.Button value="ex_binance">Binance</Radio.Button>
        <Radio.Button value="ex_ftx">FTX</Radio.Button>
      </Radio.Group>
      <br />
      <img src={logo_ex}
        width='18%'
      />
      <br />
      Show Timeframe
      <br />
      <Checkbox.Group options={listTFOptions} defaultValue={listCheckedTF} onChange={changeCheckTF} />
      <br />
      Show percent
      <br />
      <Checkbox.Group options={listTFOptions} defaultValue={["15m"]} onChange={changeCheckPercent} />
      <br />

      <br />
      <Checkbox.Group options={listDetail} defaultValue={["spot_per_future"]} onChange={changeDetailData} />
      <br />

      <p style={{ display: 'inline', fontSize: 14, fontWeight: 'bold', color: "#d6f7ff" }}>Overbought <InputNumber min={0} max={100} value={overRSI} onChange={changeOverRsi} /></p>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <p style={{ display: 'inline', fontSize: 14, fontWeight: 'bold', color: "#d6f7ff" }}>Oversold<InputNumber min={0} max={100} value={underRSI} onChange={changeUnderRsi} /></p>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <Button type="primary" onClick={updateRSI}>Update RSI</Button>
      <br />
      <Radio.Group onChange={changeRatio} value={value_ratio}>
        <Radio value={1}>show all</Radio>
        <Radio value={2}>show 1 rsi</Radio>
        <Radio value={3}>show 3 rsi</Radio>
      </Radio.Group>

      <Table dataSource={dataSource} columns={tableColumns} size="small" />
      </div>
    </div>
  );
}

export default App;