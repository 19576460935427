import React, { useState, useEffect, useRef }  from 'react';
import TradingViewWidget, { Themes } from 'react-tradingview-widget';


export default function BCharts() {
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const updateDimensions = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }
    useEffect(() => {
        try {
            // POST request using fetch inside useEffect React hook
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ ex: 'binance' })
              };
            fetch("http://146.190.94.202:5000", requestOptions)
                .then(response => response.json())
                .then(data => {
                    const list_coins = []
                    for (let _row of data.main_data){
                        list_coins.push(_row['symbol'])
                    }
                    console.log("List coins:", list_coins)
                    setWatchList(list_coins)
                });
        } catch(e) {
            console.log(e);
          }
        ////
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    const [watchlist, setWatchList] = useState(["BINANCE:BTCUSDT", "BINANCE:ETHUSDT", "BINANCE:SHIBUSDT"]);

    return (
        <TradingViewWidget
            symbol="BINANCE:ETHUSDT"
            theme={Themes.DARK}
            locale="en"
            timezone="Asia/Ho_Chi_Minh"
            width={width-20}
            height={height-10}
            watchlist={watchlist}
            hide_side_toolbar={false}
        />
    );
}
